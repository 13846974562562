<template>
   <v-container class="pa-4 text-center">
    <v-row
        class="fill-height"
        align-item="center"
        justify="center"
    >
        <template v-for="item in itemList">
            <v-col
                :key="item.id"
                cols="12"
                md="4"
            >
                <v-hover v-slot="{ hover }">
                    <v-card
                        :elevation="hover ? 12:2"
                        :class="{'on-hover': hover}"
                    >
                        <v-img
                            :src="item.img"
                            height="225px"
                        >
                            <v-card-title class="text-h6 white--text">
                                <v-row
                                    class="fill-height flex-column"
                                    justify="space-between"
                                >
                                    <p v-if="item.icon !== '' && item.icon !== null" class="mt-4 subheading text-left">
                                        <v-icon size="x-large" color="secondary">
                                            {{ item.icon }}
                                        </v-icon>
                                    </p>
                                    <p v-else> {{ item.name }}</p>
                                    <div>
                                        <p class="ma-0 text-body-1 font-weight-bold font-italic text-left">
                                            {{ item.description }}
                                        </p>
                                        <p class="text-caption font-weight-medium font-italic text-left">
                                            {{ item.subtext }}
                                        </p>
                                    </div>

                                    <div class="align-self-center">
                                        <v-btn
                                            v-for="(icon, index) in icons"
                                            :key="index"
                                            :class="{ 'show-btns': hover }"
                                            :color="transparent"
                                            icon
                                            size="x-large"
                                            @click="next(item.name, name)"
                                        >
                                            <v-icon
                                            :class="{ 'show-btns': hover }"
                                            :color="transparent"
                                            size="50px"
                                            >
                                            {{ item.icon }}
                                            </v-icon>
                                        </v-btn>
                                    </div>
                                </v-row>
                            </v-card-title>

                        </v-img>
                    </v-card>
                </v-hover>
            </v-col>
        </template>
    </v-row>
   </v-container>
</template>

<script>
export default {
    name: "OptionsComponent",
    props: {
        itemList: {
            type: Array,
            default() {
                return [];
            }
        },
        name: {
            type: String
        }
    },
    data () {
        return {
            icons: ['mdi-city-variant'],
            items: [
                {
                    title: '',
                    text: '',
                    subtext: '',
                    img: 'https://picsum.photos/1920/1080?random',
                    route: ''
                },
                {
                    title: '',
                    text: '',
                    subtext: '',
                    img: 'https://picsum.photos/1920/1080?random',
                    route: ''
                },
                {
                    title: '',
                    text: '',
                    subtext: '',
                    img: 'https://picsum.photos/1920/1080?random',
                    route: ''
                }
            ],
            transparent: 'rgba(255, 255, 255, 0)',
        }
    },
    methods: {
        next(select, opt) {
            console.log(opt);
            const filter = {
                name: opt,
                value: select
            }
            this.$store.dispatch('incrementKBStep')
            this.$store.dispatch('updateBreadcrumb', filter)
        }
    }
}
</script>


<style scoped>
    .v-card {
    transition: opacity .4s ease-in-out;
    }

    .v-card:not(.on-hover) {
    opacity: 0.6;
    }

    .show-btns {
    color: rgba(255, 255, 255, 1) !important;
    }
</style>