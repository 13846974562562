import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
//import firebase from 'firebase/app';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyCpPZq3vdB-sfKukkYHpuir-Tbrv_J5yOg",
    authDomain: "app-circet-orange-maroc.firebaseapp.com",
    projectId: "app-circet-orange-maroc",
    storageBucket: "app-circet-orange-maroc.appspot.com",
    messagingSenderId: "379977137969",
    appId: "1:379977137969:web:b9e27f4e66faf494953a10"
};  



const firebaseApp = initializeApp(firebaseConfig);
const firestore = getFirestore(firebaseApp)
//firestore.settings({timestampsInSnapshots: true})

export default firestore
