<template>
    <div class="container">
        <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        >
            <v-text-field
                v-model="name"
                :counter="100"
                :rules="nameRules"
                label="Nom du process"
                required
            ></v-text-field>
        
            <v-text-field
                v-model="description"
                :rules="nameRules"
                label="Description"
                required
            ></v-text-field>
            <v-subheader></v-subheader>
            <KBEditor/>
        
            <v-checkbox
                v-model="checkbox"
                label="Actif"
                required
            ></v-checkbox>
        
            <v-btn
                :disabled="!valid"
                color="success"
                class="mr-4"
                @click="validate"
            >
                Validate
            </v-btn>
        
            <v-btn
                color="error"
                class="mr-4"
                @click="reset"
            >
                Reset Form
            </v-btn>
        
            <v-btn
                color="warning"
                @click="resetValidation"
            >
                Reset Validation
            </v-btn>
        </v-form>
    </div>
  </template>

  <script>
    import KBEditor from '@/components/KBEditor.vue';
    export default {
        components: {
            KBEditor
        },
        data: () => ({
            valid: true,
            name: '',
            nameRules: [
                v => !!v || 'Value is required',
                v => (v && v.length >= 5) || 'Values must be more than 5 characters',
            ],
            description: '',
            emailRules: [
                v => !!v || 'E-mail is required',
                v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
            ],
            select: null,
            checkbox: false,
        }),

        methods: {
            validate () {
                this.$refs.form.validate()
            },
            reset () {
                this.$refs.form.reset()
            },
            resetValidation () {
                this.$refs.form.resetValidation()
            },
        },
    }
  </script>

<style>
</style>