<template>
    <div>
        <v-card
        elevation="15"
        loading
        >
            <v-card-text>
                <div class="font-weight-bold ml-8 mb-2">
                    <v-card-subtitle> {{processItem.subtitle}}</v-card-subtitle>
                    <v-card-subtitle> <v-icon color="secondary">mdi-hours-24</v-icon> {{loadServLvl.pdc}}</v-card-subtitle>
                    <v-card-subtitle> <v-icon color="secondary">mdi-timer-sand-complete</v-icon> {{loadServLvl.gtr}}</v-card-subtitle>
                </div>
                <v-timeline
                align-top
                dense
                >
                    <v-timeline-item
                    color="blue"
                    small
                    >
                        <div>
                            <div class="font-weight-normal">
                                <strong>Date de création</strong>
                            </div>
                            <div>{{processItem.creation_date}}</div>
                        </div>
                    </v-timeline-item>

                    <v-timeline-item
                    color="green"
                    small
                    >
                        <div>
                            <div class="font-weight-normal">
                                <strong>Derniere mise à jour</strong>
                            </div>
                            <div>{{processItem.last_update}}</div>
                        </div>
                    </v-timeline-item>
                </v-timeline>
                <v-spacer></v-spacer>
                <div  class="font-weight-light ml-8 mb-2" color="white">
                    <p>{{processItem.description}}</p>
                </div>
            </v-card-text>         
            <v-card-actions>
                <v-btn
                color="white lighten-2"
                text
                @click="dialog = true"
                >
                    Consulter
                </v-btn>
            </v-card-actions>           
        </v-card>

        <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="blue"
                >
                    <v-btn
                    icon
                    dark
                    @click="dialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>Workflow {{ processItem.title }}</v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-subheader>Description</v-subheader>
                <p>
                    {{ processItem.description }}
                </p>
                <v-divider></v-divider>
                <v-subheader>Détails</v-subheader>
                <p>
                    <!--{{ processItem.contents }}-->
                    <embed :src="processItem.url" width="100%" height="800px">
                    <v-img
                    :src="processItem.url"  
                    ></v-img>
                </p>
            </v-card>
        </v-dialog>
        
    </div>
</template>

<script>
    import VueIframe from '@/plugins/vue-iframes'
    export default {
        name: "ProcessItem",
        props: {
            processItem: {
                id: String,
                title: String,
                subtitle: String,
                description: String,
                creation_date: String,
                last_update: String,
                contents: String,
                url: String
            }
        },
        components: {
            VueIframe
        },
        data () {
            return {
                myIframe: null,
                dialog: false
            }
        },
        computed: {
            loadServLvl() {
                return this.$store.getters.getServLvl
            }
        },
        methods: {
            onLoad(frame) {
                this.myIframe = frame.contentWindow
            }
        },
        setup() {
            
        },
        created() {
            this.$store.dispatch('lookupServiceLevel');
        }
    }
</script>