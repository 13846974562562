<template>
    <div>
        <button @click="addImage">
            ajouter une image
        </button>
        <editor-content :editor="editor" />
    </div>
</template>

<script>
    import { Editor, EditorContent } from '@tiptap/vue-2';
    import StarterKit from '@tiptap/starter-kit';
    import Document from '@tiptap/extension-document';
    import Dropcursor from '@tiptap/extension-dropcursor';
    import Image from '@tiptap/extension-image';
    import Paragraph from '@tiptap/extension-paragraph';
    import Text from '@tiptap/extension-text';

    export default {
        name: "KBEditor",
        components: {
            EditorContent,
        },

        data() {
            return {
            editor: null,
            }
        },

        methods: {
            addImage() {
                const url = window.prompt('URL')

                if (url) {
                    this.editor.chain().focus().setImage({ src: url }).run()
                }
            },
        },

        mounted() {
            this.editor = new Editor({
            content: '<p>I’m running Tiptap with Vue.js. 🎉</p>',
            extensions: [
                Document,
                Paragraph,
                Text,
                StarterKit,
                Image,
                Dropcursor
            ],
            })
        },

        beforeDestroy() {
            this.editor.destroy()
        },
        
    }
</script>