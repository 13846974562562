import Vue from 'vue'
import Vuex from 'vuex'
import db from '../firebase'
import { getDocs, getDoc, collection, Timestamp } from "firebase/firestore";
import getFSDoc from '@/utils.js'

Vue.use(Vuex)


export default new Vuex.Store({
  state: {
    filters: {
      segment: '',
      severity: null
    },
    activeTab: 'ProcessView',
    segments: [],
    sl: [],
    kb: [],
    kb_step: 1,
    breadcrumb: [],
    sl_grid: {}
  },
  getters: {
    activeTab(state) {
      return state.activeTab
    },
    getBusinessSegments(state) {
      return state.segments
    },
    getKnowledgeBase(state) {
      /*const filtered = state.kb
      return filtered.filter((e) => {
        return e.segment_id === state.filter.segment_id
      })*/
      return state.kb
    }, 
    getSeverityList(state) {
      return state.sl
    },
    getKBStep(state) {
      return state.kb_step
    },
    breadCrumb(state) {
      console.log(state.breadcrumb)
      return state.breadcrumb
    },
    getServLvl(state) {
      return state.sl_grid
    }
  },
  mutations: {
    setActiveTab(state, component){
      state.activeTab = component
    },
    lookupBusinessSegments(state, bs) {
      state.segments = bs
    },
    lookupKnowledgeBase(state, kb){
      state.kb = kb
    },
    lookupSeverityList(state, sl) {
      state.sl = sl
    },
    initKBStep(state, step){
      state.kb_step = step
    },
    updateStep(state, step) {
      state.kb_step = step
    },

    lookupSeverityLevel(state, sl){
      state.sl_grid = sl
    },

    updateBreadcrumb(state, bc) {
      console.log("Update Breadcrumb");
      state.breadcrumb.push(bc)
    },

    updateFilters(state, filter) {
      console.log(filter)
      state.filters[filter.name] = filter.value
    },

    initBreadCrumb(state, text) {
      state.breadcrumb.push({
        text: text,
        disabled: true,
        href: "#"
      })
    }

  },
  actions: {
    async lookupBusinessSegments(context) {
      const tmpBS = []
      const querySnapshot = await getDocs(collection(db, 'oma_business_segments'));
      querySnapshot.forEach((doc)=>{
        tmpBS.push({
          id: doc.id,
          name: doc.data().segment_name,
          description: doc.data().description,
          img: doc.data().img,
          icon: doc.data().icon
        });
      })
      console.log(tmpBS)
      context.commit('lookupBusinessSegments', tmpBS)
    },

    async lookupSeverityList(context) {
      const tmpSL = []
      const querySnapshot = await getDocs(collection(db, 'oma_severity_grid'));

      querySnapshot.forEach((doc) =>{
        tmpSL.push({
          id: doc.id,
          name: doc.data().severity,
          description: doc.data().description,
          img: doc.data().img,
          icon: doc.data().icon
        });
      })
      context.commit('lookupSeverityList', tmpSL)
    },

    async lookupKnowledgeBase(context){
      
      const tmpKb = [];
      const querySnapshot = await getDocs(collection(db,'oma_knowledge_base'));
      querySnapshot.forEach((doc) => {
        //console.log(doc.data());
        const segment = {};

        getDoc(doc.data().segment_id).then(seg => {
          //console.log(seg.data())
          segment.id = seg.id,
          segment.name = seg.data().segment_name
        })          

        tmpKb.push({
          id: doc.id,
          title: doc.data().title,
          description: doc.data().description,
          subtitle: doc.data().subtitle,
          contents: doc.data().contents,
          url: doc.data().url,
          creation_date: new Timestamp(doc.data().creation_date.seconds, 
            doc.data().creation_date.nanoseconds).toDate().toDateString(),
          last_update: new Timestamp(doc.data().last_update.seconds, 
            doc.data().last_update.nanoseconds).toDate().toDateString(),
          segment:  segment
        })
      });
      console.log(tmpKb); 
      context.commit('lookupKnowledgeBase', tmpKb)
    },


    async lookupServiceLevel(context) {
      var tmpData;
      const tmpSL = [];
      const querySnapshot = await getDocs(collection(db, 'oma_qos_grid'));

      querySnapshot.forEach((doc)=>{
        
        var obj = {
          id: doc.id,
          gtr: doc.data().gtr,
          pcd: doc.data().pcd,
          segment: getFSDoc(doc.data().segment),
          severity: getFSDoc(doc.data().severity)
        }


        /*getDoc(doc.data().segment).then((seg) => {
          obj.segment.id = seg.id,
          obj.segment.name = seg.data().segment_name
        });

        getDoc(doc.data().severity).then((sev) => {
          obj.severity['id'] = sev.id, 
          obj.severity['name']= sev.data().severity
        });*/
        
        tmpSL.push(obj);
        console.log(tmpSL);
      });

      tmpData = tmpSL.filter((sl)=>{
        console.log(sl.segment.name, sl.severity.name)
        console.log(context.state.filters)
        if(sl.segment.name === context.state.filters.segment && sl.severity.name === context.state.filters.severity)
          return sl
      });

      console.log(tmpData);
      context.commit('lookupSeverityLevel', tmpData[0]);

    },




    initKBStep(context, step=1) {
      context.commit('initKBStep', step)
    },

    incrementKBStep(context) {
      console.log("Current step "+context.state.kb_step);
      const step = context.state.kb_step + 1;
      console.log("Going to Step "+step);
      context.commit('updateStep', step);
    },

    decrementKBStep(context) {
      let step = 1
      if(context.state.kb_state > 1) {
        step = context.state.kb_state - 1
      }
      console.log("Going to Step "+step);
      context.commit('updateStep', step)
    },

    initBreadCrumb(context, text){
      context.commit('initBreadCrumb', text)
    },

    updateBreadcrumb(context, filter) {
      const bc = {
        text: filter.select,
        disabled: true,
        href: "#"
      };

      console.log(filter)
      context.commit('updateBreadcrumb', bc)
      context.commit('updateFilters', filter)
    }
  },
  modules: {
  }
})
