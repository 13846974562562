<template>
    <div class="contents">
        <div class="sidebar">
            <VerticalMenue />
        </div>
        <div class="main_container">
            <v-card>
                <v-card-title> Base de Connaissances</v-card-title>
                <v-card-subtitle>
                    <AppBreadcrumbs :items-list="breadCrumb" app_name="Base de Connaissances"/>
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <div class="stepper">
                        <v-stepper non-linear v-model="step">
                            <v-stepper-header>
                                <v-stepper-step
                                    :complete="e1 > 1"
                                    step="1"
                                    editable
                                    @click="setStep(1)"
                                >
                                    Segment
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step
                                    :complete="e1 > 2"
                                    step="2"
                                    editable
                                    @click="setStep(2)"
                                >
                                    Criticité
                                </v-stepper-step>

                                <v-divider></v-divider>

                                <v-stepper-step
                                    :complete="e1 > 3"
                                    step="3"
                                    name="process"
                                    editable
                                    @click="setStep(3)"
                                >
                                    Procédures
                                </v-stepper-step>

                            </v-stepper-header>
                            <v-stepper-items>
                                <v-stepper-content step="1">
                                    <v-lazy>    
                                        <OptionsComponent :item-list="loadBS" name="segment"/>
                                    </v-lazy>
                                </v-stepper-content>
                                <v-stepper-content step="2">
                                    <v-lazy>    
                                        <OptionsComponent :item-list="loadSL" name="severity"/>
                                    </v-lazy>
                                    
                                </v-stepper-content>
                                <v-stepper-content step="3">
                                    <ProcessList />
                                </v-stepper-content>
                            </v-stepper-items>
                        </v-stepper>
                    </div>
                </v-card-text>
            </v-card>  
        </div>
    </div>
</template>

<script>
    import VerticalMenue from '@/components/VerticalMenu.vue';
    import OptionsComponent from '@/components/OptionsComponent.vue';
    import ProcessList from '@/components/ProcessList.vue';
    import AppBreadcrumbs from '@/components/AppBreadcrumbs.vue';
    //import store from '@/store/index.js'


    export default {
        name: 'KnowledgeBase',
        data () {
            return {
                e1: 1,
               
            }
        },
        components: {
           VerticalMenue,
           OptionsComponent,
           ProcessList,
           AppBreadcrumbs
        },
        computed: {
            loadBS(){
                return this.$store.getters.getBusinessSegments
            },
            loadSL(){
                return this.$store.getters.getSeverityList
            },
            step() {
                return this.$store.getters.getKBStep
            },
            breadCrumb(){
                return this.$store.getters.breadCrumb
            }
        },
        created() {
            //console.log("KB creation");
            this.$store.dispatch('lookupBusinessSegments');
            this.$store.dispatch('lookupSeverityList');
            this.$store.dispatch('initKBStep');
            this.$store.dispatch('initBreadCrumb', "Base de Connaissances")

            //console.log("KB created");
        },
        methods: {
            setStep(step) {
                this.$store.dispatch("initKBStep", step);
            }
        }
    }
</script>

<style>
    .contents {
        width: 100%;
        display: flex;
        gap: 2px;
    }
    
    .main_container {
        width: 100%;
        display: flex;
        flex-direction: column
    }

    .stepper{
        flex: 2;
        justify-content: space-evenly
    }
</style>