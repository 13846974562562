<template>
    <div>Traitement</div>
</template>


<script>

export default {
    name: "ProcessView"
}
</script>
