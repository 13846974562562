<template>
   <v-expansion-panels focusable>
        <v-expansion-panel
        v-for="item in  loadKB "
        :key="item.id"
        >
            <v-expansion-panel-header>
                 {{ item.title }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
                <ProcessItem :process-item="item"/>
            </v-expansion-panel-content>
        </v-expansion-panel>
   </v-expansion-panels>
</template>


<script>
    import ProcessItem from '@/components/ProcessItem.vue'
    //import store from '@/store/index.js'

    export default {
        name: "ProcessList",
        data () {
            return {
                kb: []
            }
        },
        components: {
            ProcessItem
        },
        created() {
            this.$store.dispatch('lookupKnowledgeBase');
        },
        computed: {
            loadKB() {
                return this.$store.getters.getKnowledgeBase
            }
        } 
    }
</script>