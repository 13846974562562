import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import Editor from '@morioh/v-quill-editor';

import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@morioh/v-quill-editor/dist/editor.css';

  
Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  Editor,
  render: h => h(App)
}).$mount('#app')