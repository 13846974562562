import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            dark: {
                primary: "#1E1E1E",
                secondary: "#FFFFFF",
                accent: "#51b45b",
                error: "#FF0000"
            }
        }
    }
});
