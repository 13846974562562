<template>
  <div class="screen">
        <div  class="main_menu">
            <MainMenue />
        </div>
        <section class="app_contents">
          <keep-alive>
            <component :is="getActiveTab"/>
          </keep-alive>
        </section>
    </div>
</template>

<script>
  import MainMenue from '@/components/MainMenue.vue';
  import KnowledgeBase from './KnowledgeBase.vue';
  import ProcessView from './ProcessView.vue';
  import store from '@/store/index.js';

  export default {
    name: 'HomeView',

    components: {
      MainMenue,
      ProcessView,
      KnowledgeBase
    },

    data () {
      return {
        activeTab: 'ProcessView'
      }
    },

    computed: {
      getActiveTab(){
        return store.getters.activeTab;
      }
    }
  }
</script>

<style>
  .main_menu {
    width: 100%;
  }

  .app_contents {
    width: 100%;
  }  
</style>