<template>
   <v-app-bar
            color="primary"
            dark
            shrink-on-scroll
            prominent
            src="https://picsum.photos/1920/1080?random"
            fade-img-on-scroll
            scroll-target="#scrolling-techniques-3"
        >
            <v-img
            alt="Vuetify Logo"
            class="shrink mr-2"
            contain
            src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
            transition="scale-transition"
            width="40"
            />

            <template v-slot:img="{ props }">
                <v-img
                v-bind="props"
                gradient="to top right, rgba(100,115,201,.7), rgba(25,32,72,.7)"
                ></v-img>
            </template>

            <v-toolbar-title>Page title</v-toolbar-title>
            <v-spacer></v-spacer>

            <template v-slot:extension>
                <v-tabs  grow>
                    <!--<v-tab @click="activeTab = 'ProcessView'">Traitement</v-tab>
                    <v-tab @click="activeTab = 'KnowledgeBase'">Base de Connaissance</v-tab> -->

                    <v-tab @click="toggleComponent('DashboardView')">Dashboard</v-tab>
                    <v-tab @click="toggleComponent('ProcessView')">Traitement</v-tab>
                    <v-tab @click="toggleComponent('KnowledgeBase')">Base de Connaissance</v-tab>

                </v-tabs>
            </template>

            <v-btn icon>
                <v-icon>mdi-account-lock-open</v-icon>
            </v-btn>
   </v-app-bar>
</template>

<script>
import store from '@/store/index.js'

export default {
    name: "MainMenue",
    setup() {
        
    },
    methods: {
        toggleComponent(component) {
            store.commit('setActiveTab', component);
        }
    }
}
</script>