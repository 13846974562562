<template>
    <div>
        <v-breadcrumbs
            :items="itemsList"
            divider="-"
        ></v-breadcrumbs>
    </div>
</template>

<script>
export default {
    name: "AppBreadcrumbs",
    setup() {
        
    },
    props: ['itemsList'],
    
}
</script>